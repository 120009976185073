export function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const i = new Image();

    i.addEventListener('load', () => {
      resolve(i);
    }, false);
    i.addEventListener('error', reject, false);

    i.src = src;
  });
}

// used for tutor active hours tooltip on tutor profile page and contact page
export function convertStringToReadableTime(strDate, tzName, withZip, moment) {
  // strDate should look like "08:30:00". This is in the tutor's timezone.
  let hour = Number(strDate.substring(0,2));
  let minute = Number(strDate.substring(3,5));
  let now = new moment();
  let browserTimeZone = moment.tz.guess();

  // Browser offset already takes DST into consideration. 
  let browserOffset = now.utcOffset() / 60;

  // Timezone name is something like "America/New_York" - we're using this because it already 
  // takes into account special cases like "America/Denver" and "America/Phoenix" where their 
  // offsets are sometimes the same, depending on DST
  let tutorOffset = (now.tz(tzName)).utcOffset() / 60;

  // take away tutorOffset from the hour to get to UTC, then add back 
  // browserOffset to get the hour to be in the browser's timezone
  let newHour = hour - tutorOffset + browserOffset;
  if (withZip) {
    return now.tz(browserTimeZone).set({'hour': newHour, 'minute': minute}).format("h:mm a zz");
  } else {
    return now.set({'hour': newHour, 'minute': minute}).format("h:mm a");
  }
}

// Given our current availability format, grab only the available hours for a day
export function getAvailableTimesForDay(dayAvailability) {
  let i = 0,
    l = dayAvailability.length,
    times = [],

        // Based on TutorAvailabilityStatusEnum
    tutorAvailabilityStatuses = ['notAvailable', 'booked', 'available'];

  for (; i < l; i++) {
    if (dayAvailability[i] > 0) {
      times.push({ hour: i, status: tutorAvailabilityStatuses[dayAvailability[i]] || 'unknown' });
    }
  }
  return times;
}

export function formatFriendlyHour(dateMoment) {
  if (dateMoment && dateMoment._isAMomentObject) {
    if (dateMoment.hour() === 0) {
      return 'Midnight';
    }
    else if (dateMoment.hour() === 12) {
      return 'Noon';
    }

    return dateMoment.format('h:mm a');
  }
  return dateMoment;
}

export function getUserTimeZoneOffset() {
  // TIMEZONE_ISSUES: the .NET backend won't take a non-int TZ offset (i.e. 4.5)
  // round to an int to avoid API errors
  const offset = (new Date()).getTimezoneOffset() / 60;
  return parseInt(offset, 10);
}

// helper function - get the user's computer's time zone and pass it to conversion function
// separated out so that the main logic can be testable
export function convertUTCAvailabilityToLocalTime(availabilityData) {
  let timezoneOffset = getUserTimeZoneOffset();

  return convertUTCAvailabilityToTimeZone(availabilityData, timezoneOffset);
}

// given a timezone offset, shift the tutor's UTC availability to match
export function convertUTCAvailabilityToTimeZone(availabilityData, timezoneOffset = 0) {
  let keys = Object.keys(availabilityData),
    i = 0,
    l = keys.length,
    allAvailability = [],
    offsetHours;

  if (availabilityData === null) {
    return null;
  }

  // mash all the hours together
  for (; i < l; i++) {
    allAvailability = allAvailability.concat(availabilityData[keys[i]]);
  }

  // shift the array by the timezone offset
  offsetHours = allAvailability.splice(timezoneOffset);
  allAvailability = offsetHours.concat(allAvailability);

  // recreate daily hour blocks
  for (i = 0; i < l; i++) {
    availabilityData[keys[i]] = allAvailability.splice(0, 24);
  }

  return availabilityData;
}

export function convertCamelcaseToSeparateWords(input, isUppercaseFirstChar) {
  const spacedString = input.split(/(?=[A-Z])/).join(' ').toLowerCase();

  if (isUppercaseFirstChar) {
    return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
  }

  return spacedString;
}

export function convertTimeOfDayToAvailability(timeOfDayInfos) {
  if (!timeOfDayInfos || !timeOfDayInfos.length) {
    return null;
  }

  const timeOfDays = ['Morning', 'Afternoon', 'Evening'];

  const availability = {};

  for (const timeOfDayInfo in timeOfDayInfos) {
    const originalTimeOfDay = timeOfDayInfos[timeOfDayInfo];

    for (const timeOfDayIndex in timeOfDays) {
      const timeOfDay = timeOfDays[timeOfDayIndex];

      if (originalTimeOfDay.indexOf(timeOfDay) !== -1) {
        const day = originalTimeOfDay.substring(0, originalTimeOfDay.indexOf(timeOfDay));
        availability[day] = Object.assign({}, availability[day], convertTimeOfDayToTimeBlocks(timeOfDay));
      }
    }
  }

  return availability;
}

export function convertTimeOfDayToTimeBlocks(time) {
  const morningTimes = {
    sixAM: true,
    sevenAM: true,
    eightAM: true,
    nineAM: true,
    tenAM: true,
    elevenAM: true,
  };

  const afternoonTimes = {
    noon: true,
    onePM: true,
    twoPM: true,
    threePM: true,
    fourPM: true,
  };

  const eveningTimes = {
    fivePM: true,
    sixPM: true,
    sevenPM: true,
    eightPM: true,
    ninePM: true,
  };

  switch (time) {
    case 'Morning':
      return morningTimes;
    case 'Afternoon':
      return afternoonTimes;
    case 'Evening':
      return eveningTimes;
    default:
      return {};
  }
}

export function animateBlink(element, that) {
  const $customInput = $(element).parent();
  $customInput.addClass('input-blink');

  setTimeout(() => {
    $customInput.removeClass('input-blink');
  }, 800);
}

export function truncateString(str, length) {
  if (!str) {
    return null;
  }

  const truncateLength = str.length > length ? length : str.length;

  return str.substring(0, truncateLength);
}

export function parseCaughtError(payload, error) {
  // Various failure messages when a connection is lost via fetch.
  const fetchFailureMessages = ['Network request failed', 'Failed to fetch', 'NetworkError when attempting to fetch resource.'];

  if (typeof error === 'object') {
    if (error.message) {
      payload.ExceptionMessage = error.message;
    }
    else {
      let errorMessage = JSON.stringify(error);
      if (errorMessage == '{}') {
        errorMessage = error.toString();
      }
      payload.ExceptionMessage = errorMessage;
    }

    if (error.errorKey) {
      payload.ErrorKey = error.errorKey;
    }

    if (error.stack) {
      payload.ExceptionStackTrace = error.stack;
    }

    if (error.status && !payload.ApiResponseCode) {
      payload.ApiResponseCode = error.status;
    }
  }
  else if (error) {
    if (!payload.ExceptionMessage) {
      payload.ExceptionMessage = error.toString();
    }
  }

  // If fetch fails, it is usually due to a network connection lost,
  // therefore there is not anything we can do, but it is worth monitoring.
  if (fetchFailureMessages.indexOf(payload.ExceptionMessage) !== -1) {
    payload.LogLevel = 'Info';
  }

  return payload;
}

export function getCookie(key) {
  const cookieKey = key + "=";
  const cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieKey) == 0) {
          return cookie.substring(cookieKey.length, cookie.length);
      }
  }
  return "";
}

export function getLocalStorage(key) {
  let item = null;

  try {
    item = window.localStorage.getItem(key);
  }
  catch(e) {}

  return item;
}

export function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, value);
  }
  catch(e) {}
}

export function removeLocalStorage(key) {
  try {
    window.localStorage.removeItem(key);
  }
  catch(e) {}
}

export function isElementDescendant(parent, child) {
  if (parent === child) {
    return true;
  }

  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
}

export function getGeolocation() {
  return new Promise((resolve, reject) => {
    if (!window.navigator ||
        !window.navigator.geolocation ||
        !window.google ||
        !window.google.maps) {
      reject();
    }

    window.navigator.geolocation.getCurrentPosition((pos) => {
      new window.google.maps.Geocoder().geocode({
        latLng: new window.google.maps.LatLng(pos.coords.latitude, pos.coords.longitude),
      }, (res, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && typeof res[0] !== 'undefined') {
          const address = res[0].formatted_address.match(/,\s\w{2}\s(\d{5})/);

          if (address && address.length >= 2) {
            const zip = address[1];

            setLocalStorage('lastGeolocatedZip', zip);

            resolve(zip);
          }
          else {
            reject('address too short');
          }
        }
        else {
          reject('geocoder status was not OK');
        }
      }, () => {
        reject('geocoder failed');
      });
    });
  });
}
