<template>
  <section>
    <template>
      <div v-if="selectedLessonTimeDisplayDate" class="spc-sm-s">
        <span class="text-semibold">Requested lesson time</span>
        <a href="#" @click.prevent="toggleLessonTimes" class="lesson-time-display text-semibold">
          <i class="wc-calendar"></i>
          {{ selectedLessonTimeDisplayDate }}
        </a>
      </div>
      <div v-show="showLessonTimes">
        <div class="medium-text-left text-semibold spc-sm-s" for="lesson-time-calendar">Request a time to meet <span class="text-light">(optional)</span></div>
        <div class="lesson-time-calendar-wrapper">
          <div class="lesson-time-nav">
            <a :class="{ 'week-disabled': isCurrentWeek }"
               @click.prevent="decrementWeek">
              <i class="wc-chevron-left"></i>
            </a>
            <span v-html="calendarDateRange"></span>
            <a :class="{ 'week-disabled': !haveNextWeekData }"
               @click.prevent="incrementWeek">
              <i class="wc-chevron-right"></i>
            </a>
          </div>
          <div class="lesson-time-calendar spc-sm" id="lesson-time-calendar">
            <div class="clearfix lesson-time-day"
                 :class="{ 'lesson-time-disabled': day.isPast || day.isToday, 'lesson-time-selected': day.isSelectedDay }"
                 v-for="day in weekAvailability">
              <div class="column small-4" :class="{ 'text-semibold': day.isToday }">
                <template v-if="day.isToday">
                  Today
                </template>
                <template v-else>
                  {{ day.dayOfWeek }} <span :class="{ 'text-light': !day.isSelectedDay }">{{ day.day }}</span>
                </template>
              </div>
              <div class="column small-8">
                <template v-if="day.isToday">
                  <span class="text-light">
                    Contact Tutor
                  </span>
                </template>
                <template v-else>
                  <template v-if="day.isSelectedDay">
                    {{ selectedLessonTimeDisplayTime }} <a class="wc-times text-light lesson-time-clear" @click.prevent="clearLessonTime"></a>
                  </template>
                  <template v-else>
                    <template v-if="day.hasAvailability">
                      {{ day.availabilityStart }} &ndash; {{ day.availabilityEnd }} <i class="wc-caret-down hide"></i>
                    </template>
                    <span v-else class="text-light">
                      No times
                    </span>
                  </template>
                </template>
              </div>
              <select v-if="day.hasAvailability" @change="setLessonTime" class="availability-select-box">
                <option disabled selected value=""> Choose a Time </option>
                <option v-for="time in day.availableTimes" :value="time.value" :disabled="time.isBooked" v-html="time.text"></option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import moment from 'moment-timezone/moment-timezone';
import * as Utils from './utils';

let availabilityDayKeyFormat = 'YYYYMMDD';

function formatDisplayHour(h) {
  if (typeof h !== undefined && h !== null) {
    return Utils.formatFriendlyHour(moment().startOf('day').add(h, 'h'));
  }

  return null;
};

function formatSelectHours(times, day) {
  let momentHours = times.map(time => moment(day).add(time.hour, 'h')),
      i = 0,
      l = momentHours.length,
      selectHours = [];

  for (; i < l; i++) {
    let isBooked = (times[i].status === 'booked');

    selectHours.push({
      value: momentHours[i].format(),
      text: Utils.formatFriendlyHour(momentHours[i]) + (isBooked ? '&ndash; Booked' : ''),
      isBooked: isBooked,
    });
  }

  return selectHours;
};

export default {
  props: {
    schedulerLocation: {
      type: String,
      required: false,
      default: "consign-control"
    },
  },
  data() {
    return {
      showLessonTimes: true,
      currentDate: moment().format(),
      lessonTimes: [],
      selectedLessonTime: null,
    }
  },

  computed: {
    specificAvailability() {
      const availability = window.wyzant.matchAppInfo.tutorAvailability;
      return Utils.convertUTCAvailabilityToLocalTime(availability);
    },

    lessonTimeState() {
      const tutorId = window.wyzant.matchAppInfo.tutorId;
      return Utils.getLocalStorage("lesson-request-time-" + tutorId);
    },

    calendarDateRange() {
      let isSameMonth = this.calendarStart.isSame(this.calendarEnd, 'month');

      return this.calendarStart.format('MMM D').toUpperCase() + ' &ndash; ' + this.calendarEnd.format(isSameMonth ? 'D' : 'MMM D').toUpperCase();
    },

    calendarStart() {
      return moment(this.currentDate);
    },

    calendarEnd() {
      return moment(this.currentDate).add(1, 'w');
    },

    isCurrentWeek() {
      return moment(this.currentDate).isBetween(moment().startOf('week'), moment().endOf('week'));
    },

    haveNextWeekData() {
      let nextWeekData = this.specificAvailability[this.calendarEnd.format(availabilityDayKeyFormat)];

      return !!nextWeekData;
    },

    weekAvailability() {
      let day = moment(this.calendarStart),
          now = moment(),
          availability = [];

      while (day.isBefore(this.calendarEnd)) {
        let dayAvailability = {},
            allTimes,
            availableTimes,
            startHour,
            endHour;

        allTimes = this.specificAvailability[day.format(availabilityDayKeyFormat)];

        // This shouldn't happen, but if we don't have data for this day of the week, just stop here
        if (!allTimes) {
          break;
        }

        availableTimes = Utils.getAvailableTimesForDay(allTimes);

        // Grab the start and end times for this day (we only show the hours available or booked)
        if (availableTimes.length) {
          startHour = formatDisplayHour(availableTimes[0].hour);
          endHour = formatDisplayHour(availableTimes.slice(-1)[0].hour + 1);
        }

        dayAvailability = {
          day: day.format('D'),
          dayOfWeek: day.format('ddd'),
          isPast: day.isBefore(now, 'd'),
          isToday: day.isSame(now, 'd'),
          isSelectedDay: day.isSame(this.selectedLessonTime, 'd'),
          availabilityStart: startHour,
          availabilityEnd: endHour,
          hasAvailability: availableTimes.length,
        }

        if (!dayAvailability.isToday) {
          dayAvailability.availableTimes = formatSelectHours(availableTimes, day.format('YYYYMMDD'));
        }

        availability.push(dayAvailability);
        day.add(1, 'd');
      }

      return availability;
    },

    selectedLessonTimeDisplayTime() {
      return this.selectedLessonTime ? Utils.formatFriendlyHour(moment(this.selectedLessonTime)) : '';
    },

    selectedLessonTimeDisplayDate() {
      let dateMoment = moment(this.selectedLessonTime);

      return this.selectedLessonTime ? dateMoment.format('ddd, MMM DD') + ' at ' + Utils.formatFriendlyHour(dateMoment) : '';
    },
  },
  methods: {
    toggleLessonTimes() {
      this.showLessonTimes = (this.showLessonTimes === false);
    },

    incrementWeek() {
      if(this.haveNextWeekData) {
        this.currentDate = moment(this.currentDate).add(1, 'w').format();
      }
    },

    decrementWeek() {
      if(!this.isCurrentWeek) {
        this.currentDate = moment(this.currentDate).subtract(1, 'w').format();
      }
    },

    setLessonTime(e) {
      this.selectedLessonTime = e.target.value;
      const selectBoxes = document.querySelectorAll('.availability-select-box');
      selectBoxes.forEach(el => {
        if(el != e.target) {
          el.value = '';
        }
      });
      this.toggleLessonTimes();
    },

    clearLessonTime() {
      this.selectedLessonTime = '';
    },
  },

  watch: {
    selectedLessonTime(val, oldVal) {
      let time = null;

      if (val) {
        time = moment.utc(val).format();
      }
      const tutorId = window.wyzant.matchAppInfo.tutorId;
      Utils.setLocalStorage("lesson-request-time-" + tutorId, time);
      Utils.setLocalStorage("lesson-scheduler-location", this.schedulerLocation);
    },

    lessonTimeState(val, oldVal) {
      this.selectedLessonTime = val;
    }
  },

  created() {
    this.selectedLessonTime = null;
  },
};
</script>

<style lang="less">
@import '../match/less/_variables';

.lesson-time-display {
  display: block;
  border: 1px solid @borderGray;
  border-radius: 5px;
  background: #fff;
  padding: .5em .7em;

  &:hover {
    text-decoration: none;
  }
}
.lesson-time-nav {
  span {
    margin: 0 10px;
  }
  .week-disabled {
    color: @gray-400;
  }
}
.lesson-time-calendar-wrapper {
  position: relative;

  .ui-loader {
    display: none;
  }
}
.lesson-time-calendar {
  border-radius: 5px;
  border: 1px solid @gray-400;
}
.lesson-time-day {
  padding: 0.7em 0;
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid @gray-400;
  }
  &:not(.lesson-time-disabled):hover {
    background: @backgroundBlue;

    .wc-caret-down {
      display: inline-block;
      color: @blue-500;
      margin-left: 4px;
    }
  }
  .wc-caret-down {
    display: none;
  }
  select {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.lesson-time-disabled {
  color: @textColorMuted;
}
.lesson-time-selected {
  font-weight: 600;
  color: @blue-500;
}
.lesson-time-clear {
  float: right;
  position: relative;
  z-index: 50;
  line-height: 1.5em;

  &:hover {
    text-decoration: none;
  }
}
</style>
